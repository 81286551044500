import Grid from "@mui/material/Grid";
import { Box, useMediaQuery } from "@mui/material";
import About from "../components/About";
import BottomBar from "../components/BottomSection";

function DashedLine() {
  return (
    <Grid item xs={12} sx={{ textAlign: "center" }}>
      <hr className="dashed" style={{ width: "80%" }} />
    </Grid>
  );
}
export default function Work() {
  const isPhone = useMediaQuery("(max-width:600px)");
  return (
    <Grid item xs={12} md={9}>
      <Grid container spacing={2} fontSize={18}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Box
            sx={{
              width: isPhone ? "80%" : 300,
              height: isPhone ? "auto" : 300,
              maxHeight: "90vh",
              paddingBottom: 2,
              borderRadius: 1,
              overflow: "hidden",
              // border: "1px solid #e0e0e0",
            }}
          >
            <Box
              component="img"
              sx={{
                width: "100%",
                height: isPhone ? "auto" : 300,
                objectFit: "contain",
              }}
              src="profile.jpg"
            />
          </Box>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Box
            sx={{
              width: "80%",
              paddingTop: 0,
              fontWeight: "bold",
              paddingBottom: 0,
              color: "#EEF0E5",
            }}
          >
            About
          </Box>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <About />
        </Grid>
        <DashedLine />
        <Grid item xs={12} display="flex" justifyContent="center">
          <BottomBar />
        </Grid>
      </Grid>
    </Grid>
  );
}
